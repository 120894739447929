'use client';

import { extendTheme } from '@chakra-ui/react';
import { Poppins } from 'next/font/google';
import type { ReactNode } from 'react';
import { useTenantConfig } from './providers/tenant_provider';
import { ProvidersClient } from './providers_client';

const poppins = Poppins({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
});

interface props {
  children: ReactNode;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  theme: any;
}

export default function LayoutClient({ children, theme }: props) {
  const extendedTheme = extendTheme({ theme });
  const config = useTenantConfig();

  return (
    <html lang='pt-BR'>
      <style jsx global>
        {`
          :root {
            --font-poppins: ${poppins.style.fontFamily};
          }
        `}
      </style>
      <head>
        <title>Backoffice</title>
        <meta name='description' content='Gerenciamento de cadastro' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link rel='icon' href={config.favicon} />
      </head>
      <body>
        <ProvidersClient theme={extendedTheme}>{children}</ProvidersClient>
      </body>
    </html>
  );
}
